/* global KTUtil */
/*
 * Load Metronic components into the global namespace.
 */

// KTUtil is a dependency for other components.
global.KTUtil = KTUtil;

import KTBlockUI from "~/metronic/js/components/blockui.js";
import KTCookie from "~/metronic/js/components/cookie.js";
import KTDialer from "~/metronic/js/components/dialer.js";
import KTDrawer from "~/metronic/js/components/drawer.js";
import KTEventHandler from "~/metronic/js/components/event-handler.js";
import KTFeedback from "~/metronic/js/components/feedback.js";
import KTImageInput from "~/metronic/js/components/image-input.js";
import KTMenu from "~/metronic/js/components/menu.js";
import KTPasswordMeter from "~/metronic/js/components/password-meter.js";
import KTScroll from "~/metronic/js/components/scroll.js";
import KTScrollTop from "~/metronic/js/components/scrolltop.js";
import KTSearch from "~/metronic/js/components/search.js";
import KTStepper from "~/metronic/js/components/stepper.js";
import KTSticky from "~/metronic/js/components/sticky.js";
import KTSwapper from "~/metronic/js/components/swapper.js";
import KTToggle from "~/metronic/js/components/toggle.js";

// TODO: comment out unused components?
global.KTBlockUI = KTBlockUI;
global.KTCookie = KTCookie;
global.KTDialer = KTDialer;
global.KTDrawer = KTDrawer;
global.KTEventHandler = KTEventHandler;
global.KTFeedback = KTFeedback;
global.KTImageInput = KTImageInput;
global.KTMenu = KTMenu;
global.KTPasswordMeter = KTPasswordMeter;
global.KTScroll = KTScroll;
global.KTScrollTop = KTScrollTop;
global.KTSearch = KTSearch;
global.KTStepper = KTStepper;
global.KTSticky = KTSticky;
global.KTSwapper = KTSwapper;
global.KTToggle = KTToggle;

import * as bootstrap from "bootstrap";
global.bootstrap = bootstrap;
